<p-toast
  position="top-right"
  [breakpoints]="{ '1024px': { right: '0' } }"></p-toast>

<pv-header *ngIf="showPVHeader" [searchVisible]="false"></pv-header>

<router-outlet></router-outlet>

<pv-token-expired
  [moduleConfig]="tokenExpiredModuleConfig"
  [show]="showTokenExpiredModal"
  (openAuthModal)="handleOpenAuthModal()"></pv-token-expired>
