import { JourneyMetadataService } from '@pv-frontend/pv-shared-services/journey-metadata-service';

import { allJourneyDetails } from './const/journey-info';

export class JourneyMetaDataServiceImpl extends JourneyMetadataService {
  public override getJourneyConfig(elementPath: string): unknown {
    const answer = elementPath.split('.').reduce((acc, curr) => {
      return acc[curr];
    }, allJourneyDetails);

    return answer;
  }
}
