import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';
import { PointsXChangeConfig } from '@pv-frontend/points-xchange';

import { giftVouchersConfig } from './gift-vouchers';
import { pointsXChangeConfig } from './points-xchange';
import { pointsXChangeDesktopConfig } from './points-xchange-desktop';

export interface AllJourneyConfig {
  journey: GiftVouchersConfig;
  pointsXChange: PointsXChangeConfig;
}

export const allJourneyDetails: AllJourneyConfig = {
  journey: giftVouchersConfig,
  pointsXChange:
    window.innerWidth < 768 ? pointsXChangeConfig : pointsXChangeDesktopConfig,
};
