import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TokenExpiredConfig } from '@pv-frontend/authentication';
import { JOURNEY_METADATA_SERVICE_TOKEN } from '@pv-frontend/pv-shared-services/journey-metadata-service';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Subject, filter, takeUntil } from 'rxjs';

import { JourneyMetaDataServiceImpl } from '../journey-metadata-impl.service';

import { appConstants } from './app.constants';

@Component({
  selector: 'fi-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private destroy$: Subject<void> = new Subject<void>();
  public tokenExpiredModuleConfig?: Partial<TokenExpiredConfig>;
  private readonly TokenExpiredConfigPath: string = 'journey.tokenExpired';
  public showTokenExpiredModal = false;
  private readonly POINTS_XCHANGE_PATH = appConstants.pointsXChangeUrlKeyword;
  public showPVHeader: boolean = false;

  public constructor(
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserService,
    @Inject(JOURNEY_METADATA_SERVICE_TOKEN)
    private journeyMetadataService: JourneyMetaDataServiceImpl,
    private router: Router
  ) {
    this.setTokenExpiredModalConfig(this.TokenExpiredConfigPath);
    this.showTokenExpiredModal =
      !window.location.pathname.includes('sso_login') &&
      this.pvUserService.isTokenExpired();

    this.pvUserService.tokenExpiredObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isExpired: boolean) => {
          this.showTokenExpiredModal = isExpired;
          isExpired && this.pvUserService.logoutUser();
        },
      });

    this.checkIfPointsExchangePath();
  }

  private checkIfPointsExchangePath(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe((res: NavigationEnd) => {
        this.showPVHeader = res.url.includes(this.POINTS_XCHANGE_PATH);
      });
  }

  private setTokenExpiredModalConfig(pathToView: string): void {
    this.tokenExpiredModuleConfig =
      this.journeyMetadataService.getJourneyConfig(
        pathToView
      ) as TokenExpiredConfig;
  }

  public handleOpenAuthModal(): void {
    this.showTokenExpiredModal = false;
    this.pvUserService.toggleAuthModal(true);
  }

  public subscribeToLogin(): void {
    this.pvUserService.loginModalObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (showLoginModal: boolean) => {
          if (showLoginModal) {
            this.pvUserService.toggleAuthModal(true);
          }
        },
      });
  }
}
