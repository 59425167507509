import { PointsXChangeConfig } from '@pv-frontend/points-xchange';

export const pointsXChangeConfig: PointsXChangeConfig = {
  global: {
    config: {
      pointsLabel: 'Fi-Coins',
      defaultBurnRatio: 1,
    },
  },
  landingPage: {
    listing: {
      config: {
        headingText: 'Our trusted point transfer partners',
        categories: {
          showCrossIcon: true,
          isMultiSelect: true,
          variant: 'outlined',
        },
        pointsProvider: {
          isPaginationRequired: true,
          perPage: 5,
        },
        showLinkedProviders: false,
      },
    },
  },
  transferHistoryPage: {
    style: {
      container: {
        'background-color': '#fafafa !important',
      },
      subContainer: {
        width: '100%',
      },
      navBar: {
        container: {
          display: 'none',
        },
      },
    },
  },
  transferHistoryDetailsPage: {
    config: {
      backNavUrl: {
        route: ['order-history'],
        queryParams: { pageType: 'PointsXChange' },
      },
    },
  },
};
